.StyledText {
	--lineHeightMultiplier: 1.1;
	--textVspaceMultiplier: 1.35;
	font-size: clamp(0.85rem, 1.5vw, 1rem);

	h2 {
		font-size: clamp(1.5rem, 3vw, 2rem);
		line-height: 1.25;
	}

	h3 {
		font-size: clamp(1rem, 2vw, 1.375rem);
		line-height: 1.45;
	}

	h4 {
		font-size: clamp(0.9rem, 2vw, 1.125rem);
		line-height: 1.78;
	}

	h1, h2, h3, h4 {
		margin-bottom: calc(var(--spaceUnit) * 0.45 * var(--textVspaceMultiplier, 1));
	}

	h2, h3, h4 {
		margin-top: calc(var(--spaceUnit) * 0.75 * var(--textVspaceMultiplier, 1));
	}

	p, ul li, ol li {
		line-height: 1.5;
	}

	ul, ol, p, table {
		margin-bottom: calc(var(--spaceUnit) * 0.75 * var(--textVspaceMultiplier, 1));
	}

	figcaption {
		margin-top: calc(var(--spaceUnit) * 0.5);
	}

	em {
		font-style: italic;
	}

	hr {
		margin: var(--spaceLg) 0;
		border: none;
		border-bottom: 1px solid var(--colorOutlines);
	}

	// CUSTOM BLOCKQUOTE
	blockquote {
		quotes: "“" "”" "‘" "’";
		border-top: 1px solid var(--colorOutlines);
		border-bottom: 1px solid var(--colorOutlines);
		font-family: Source Sans Pro, sans-serif;
		position: relative;
		padding: var(--spaceMd) 0;
		margin: var(--spaceLg) 0;
		font-style: italic;

		&:before {
			content: open-quote;
			color: var(--colorOutlines);
			position: absolute;
			left: 50%;
			top: .2em;
			transform: translate(-50%, -50%);
			font-size: 4em;
			font-weight: 600;
			text-shadow: 5px 0 0 var(--colorThemeAccent);
		}

		&:after {
			content: no-close-quote;
		}

		p {
			position: relative;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	// CUSTOM LIST
	ul, ol {
		list-style-type: none;

		ul, ol {
			margin-left: var(--spaceSm);
			margin-top: .5em;
			margin-bottom: .2em;
		}

		li {
			position: relative;
			padding-left: clamp(2rem, 4vw, 2.5rem);
			padding-bottom: .7em;

			&:last-child {
				padding-bottom: 0;
			}

			&:before {
				content: ' ';
				display: block;
				width: 1rem;
				height: 2px;
				border-radius: 0;
				background: var(--colorText);
				position: absolute;
				left: 0;
				top: clamp(calc(0.85rem * 0.75 - 1px), 1vw, calc(1rem * 0.75 - 1px));
				z-index: var(--layerPageZIndex);
			}

			&:last-child:after {
				display: none;
			}
		}
	}

	ol {
		list-style-type: none;
		counter-reset: li;

		li {
			&:before {
				counter-increment: li;
				content: counter(li, decimal) ".";
				background: var(--colorThemeAccent);
				color: var(--colorText);
				width: auto;
				height: auto;
				left: 0;
				top: 0;
				min-width: 1.5rem;
				text-align: left;
			}
		}

		ul > li {
			content: ' ';
		}
	}

	// tabulka
	table {
		border-collapse: collapse;
		background: white;
		overflow: hidden;
		width: 100%;
		position: relative;
		border: none;

		td, th {
			padding: clamp(0.5rem, 2vw, 1rem);
			text-align: left;
			border: 1px solid var(--colorOutlines);
		}

		thead tr {
			background: var(--colorSecondaryBg);
			color: text-contrast(var(--colorSecondaryBg));
		}

		th {
			font-weight: 700;
		}
	}

	iframe {
		border: none;
		width: 100%;
	}

	// HANGING ELEMENTS MARGIN FIX
	> *:first-child, div > *:first-child {
		margin-top: 0;
	}

	> *:last-child, div > *:last-child {
		margin-bottom: 0;
	}

	.u-responsiveTable {
		overflow-x: auto;
	}
}
