@import "abstracts/_abstracts";
@import "../../../../styled-text/_base/styled-text";

.HomeSliderItem {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
	overflow: hidden;
	display: flex;
	min-height: clamp(32rem, 60vw, 40rem);

	&-image {
		width: 50%;
		overflow: hidden;
		position: relative;

		@include media('<=sm') {
			position: absolute;
			width: 100%;
			inset: 0;
			z-index: -1;
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 8rem;
			background: linear-gradient(to right bottom, var(--colorBodyBg) 0%, var(--colorBodyBg) 50%, transparent 50%);

			@include media('<=sm') {
				width: 100%;
				background: rgba(0, 0, 0, 0.7);
			}
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&-content {
		width: auto;
		padding-bottom: 3.5rem;
		margin-left: 0;

		&-frame {
			width: 50%;
			box-sizing: border-box;
			position: relative;
			color: var(--colorText);
			display: flex;
			align-items: center;

			@include media('<=sm') {
				width: 100%;
			}
		}

		@include media(">lg") {
			margin-left: calc((min(1920px, 100vw) - var(--sizeContentWidthMedium)) / 2 - var(--sizeContentOffset));
		}
	}

	&-title {
		font-size: clamp(2rem, 4vw, 2.5rem);
		margin: 0 0 var(--spaceXs);
		color: var(--colorText);
		font-weight: 700;
		line-height: 1.2;

		@include media('<=sm') {
			color: var(--colorThemeAccent);
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-text {
		font-size: clamp(0.85rem, 1.5vw, 1rem);
		line-height: 1.5;
		margin: 0 0 var(--spaceMd);
		color: var(--colorText);

		@include media('<=sm') {
			color: var(--colorThemeAccent);
		}

		ul li {
			font-weight: 700;

			&:before {
				background: var(--colorBrand);
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&-btnHolder {
	}

}